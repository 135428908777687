<template>
  <div class="editor-immo">
    <editor-content
      class="editor-immo__content"
      :editor="editor"
      @click.prevent.stop="handleEditorClick"
    />
    <editor-menu-bar
      @click.prevent.stop="handleEditorClick"
      :editor="editor"
      v-slot="{ commands, isActive, getMarkAttrs }"
    >
      <div class="menubar is-hidden" :class="{ 'is-focused': true }">
        <form
          class="menububble__form"
          v-if="linkMenuIsActive"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <input
            class="menububble__input linkam"
            type="text"
            v-model="linkUrl"
            placeholder="link + inter"
            ref="linkInput"
            @keydown.esc="hideLinkMenu"
          />
          <button
            class="menububble__button"
            @click="setLinkUrl(commands.link, null)"
            type="button"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </form>
        <template v-else>
          <!-- Piece Joint  -->
          <div class="menubar__button">
            <file-component @files="$emit('files', $event)"></file-component>
          </div>
          <!-- Emojie  -->

          <!-- <button class="menubar__button">
          <font-awesome-icon class="img-h-14" :icon="['far', 'face-smile']" />
        </button> -->
          <!-- Link  -->

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.link() }"
            @click.prevent="showLinkModal(getMarkAttrs('link'))"
          >
            <font-awesome-icon class="img-h-14" :icon="['far', 'link']" />
          </button>
          <!-- Image  -->
          <!-- <button class="menubar__button">
          <font-awesome-icon class="img-h-14" :icon="['far', 'image']" />
        </button> -->
          <!-- BOLD  -->
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click.prevent.stop="commands.bold"
          >
            <font-awesome-icon class="img-h-14" :icon="['far', 'bold']" />
          </button>
          <!-- ITALIC  -->
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click.prevent.stop="commands.italic"
          >
            <font-awesome-icon class="img-h-14" :icon="['far', 'italic']" />
          </button>
          <!-- UNDERLINE  -->
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click.prevent.stop="commands.underline"
          >
            <font-awesome-icon class="img-h-14" :icon="['far', 'underline']" />
          </button>
          <!-- TT Taille -->
          <!-- <button class="menubar__button">
          <font-awesome-icon class="img-h-14" :icon="['far', 'text-size']" />
        </button> -->
          <!-- S BARRE  -->
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click.prevent.stop="commands.strike"
          >
            <font-awesome-icon
              class="img-h-14"
              :icon="['far', 'strikethrough']"
            />
          </button>
          <!-- A COULEUR  -->
          <!-- <v-menu
            :close-on-content-click="true"
            :nudge-width="250"
            content-class="emoji_select"
            left
            :offset-y="true"
          >
            <template v-slot:activator="{ on }">
              <button class="menubar__button" type="button" v-on="on">
                <font-awesome-icon class="img-h-14" :icon="['far', 'a']" />
              </button>
            </template>

            <div class="icon_pack text-center">
              <v-color-picker v-model="color" />
              <span
                v-for="(color, i) in colors"
                :key="i"
                class="pointer"
                :class="{
                  'is-active1': isActive.textColor({
                    level: 'text-' + color
                  })
                }"
                @click.prevent="commands.textColor({ level: 'text-' + color })"
                ><v-icon :color="color.color">mdi-circle</v-icon></span
              >
            </div>
          </v-menu> -->

          <!-- lineeeeeee  -->
          <!-- List  -->
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click.prevent.stop="commands.bullet_list"
          >
            <font-awesome-icon class="img-h-14" :icon="['far', 'list-ul']" />
          </button>
          <!-- TABULATION right  -->
          <!-- TABULATION left  -->
          <!-- Align left  -->
          <button
            class="menubar__button"
            :class="{
              'is-active': editor.activeMarkAttrs.aligntext.align === 'left'
            }"
            @click.prevent="commands.aligntext({ align: 'left' })"
          >
            <font-awesome-icon class="img-h-14" :icon="['far', 'align-left']" />
          </button>
          <!-- Align justify  -->
          <button
            class="menubar__button"
            :class="{
              'is-active': editor.activeMarkAttrs.aligntext.align === 'justify'
            }"
            @click.prevent="commands.aligntext({ align: 'justify' })"
          >
            <font-awesome-icon
              class="img-h-14"
              :icon="['far', 'align-justify']"
            />
          </button>
          <!-- Align right  -->
          <button
            class="menubar__button"
            :class="{
              'is-active': editor.activeMarkAttrs.aligntext.align === 'right'
            }"
            @click.prevent="commands.aligntext({ align: 'right' })"
          >
            <font-awesome-icon
              class="img-h-14"
              :icon="['far', 'align-right']"
            />
          </button>

          <!-- Align center  -->
          <button
            class="menubar__button"
            :class="{
              'is-active': editor.activeMarkAttrs.aligntext.align === 'center'
            }"
            @click.prevent="commands.aligntext({ align: 'center' })"
          >
            <font-awesome-icon
              class="img-h-14"
              :icon="['far', 'align-center']"
            />
          </button>
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click.prevent.stop="commands.paragraph"
          >
            <font-awesome-icon
              class="img-h-14"
              :icon="['far', 'paragraph']"
            /></button
        ></template>
        <v-dialog
          width="400"
          v-model="linkModal"
          class="ModalLink"
          scrim="#fff"
        >
          <header class="pa-4 font-weight-bold">
            {{ $t('link') }}
            <v-icon
              class="float-right"
              color="error"
              @click="linkModal = !linkModal"
              >mdi-close</v-icon
            >
          </header>
          <v-divider></v-divider>
          <div class="pa-4 pt-7 texam">
            <v-form autocomplete="off">
              <v-text-field
                dense
                label="link"
                height="40"
                v-model="linkUrl"
                hide-details
                class="mb-5 size14"
                outlined
              ></v-text-field>

              <v-checkbox
                v-model="linkNewTab"
                label="Open in new tab"
                hide-details
              ></v-checkbox>
            </v-form>

            <v-btn
              @click="sendLink(commands.link)"
              dark
              class="mt-4"
              color="#5C2DD3"
              >{{ $t('btnAdd') }}</v-btn
            >
          </div>
        </v-dialog>
      </div>
    </editor-menu-bar>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import AlignText from './tipTapComponent/tiptap-align.js'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'
import FileComponent from './fileComponent.vue'
export default {
  components: {
    EditorContent,
    EditorMenuBar,
    FileComponent
  },
  props: {
    value: {
      required: false
    }
  },
  data() {
    return {
      linkNewTab: false,
      linkUrl: null,
      linkModal: false,
      linkMenuIsActive: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new AlignText()
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          this.$emit('input', getHTML())
        }
      })
    }
  },
  methods: {
    handleEditorClick() {
      this.editor.focus()
    },
    reset() {
      this.editor.setContent('')
    },
    showLinkModal(attr) {
      const { selection, state } = this.editor
      const { from, to } = selection
      const text = state.doc.textBetween(from, to, ' ')

      if (text == '') {
        // alert('Please select some text')
        return
      }

      this.linkModal = true

      if (attr.target == '_blank') {
        this.linkNewTab = true
      }

      this.linkUrl = attr.href
    },
    sendLink(command) {
      if (this.linkNewTab) {
        command({ href: this.linkUrl, target: '_blank' })
      } else {
        command({ href: this.linkUrl })
      }

      this.linkModal = false
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>
<style>
.editor-immo .editor-immo__content .ProseMirror {
  outline: none;
  padding: 12px;
  min-height: 140px;
  line-height: 15px;
}
</style>
<style lang="scss" scoped>
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.editor-immo {
  position: relative;
  background-attachment: fixed;
  background-color: #fff;
  padding: 0px;
  border: 1px solid #0000003b;
  margin: 0px 0px 10px 0px;
  border-radius: 4px;

  &__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
      caret-color: currentColor;
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $color-black;
      color: $color-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      padding: 0.2rem 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid $color-grey;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}

.menubar {
  border: 1px solid #e5e5e5;
  background: #f6f6f6;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  padding: 5px;
  .menubar__button {
    margin-right: 4px;
    height: 25px;
  }
  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &:focus {
      outline: none;
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.button {
  font-weight: bold;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: $color-black;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  background-color: rgba($color-black, 0.1);

  &:hover {
    background-color: rgba($color-black, 0.15);
  }
}

:focus {
  outline: none;
}

.message {
  background-color: rgba($color-black, 0.05);
  color: rgba($color-black, 0.7);
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-style: italic;
}
</style>
