<template>
  <div>
    <font-awesome-icon
      id="icon-joint"
      class="img-h-14"
      :icon="['far', 'paperclip']"
      @click="handleClickFile"
    />
    <input
      type="file"
      id="fileInputActionModal"
      @change="handleFileInput"
      multiple
      style="display:none"
    />
  </div>
</template>

<script>
export default {
  methods: {
    handleClickFile() {
      document.getElementById('fileInputActionModal').click()
    },
    handleFileInput(e) {
      let files = e.target.files
      if (!files || files.length === 0) {
        return
      }
      this.$emit('files', files)
      document.getElementById('fileInputActionModal').value = ''
    }
  }
}
</script>

<style></style>
